<template>
  <el-form class="formula-dialog" ref="form">
    <el-row>
      <el-col :md="24" :sm="24" :xs="24">
        <div class="form-right">
          <div class="d-flex">
            <div class="mr-1">
              <img
                src="@/assets/img/template-icons/calculator.svg"
                alt="icon"
                width="30"
              />
            </div>
            <div class="d-flex align-center">
              <h3 class="h3 f-weight-500 mr-1">Generate Formula</h3>
              <el-tag
                v-if="field.selected_fields.length"
                :class="
                  validate() == true
                    ? 'float-right el-tag--success'
                    : 'el-tag--danger'
                "
                >{{ validate() == true ? "VALID" : "INVALID" }}</el-tag
              >
            </div>
          </div>
          <div
            class="formula-box"
            :class="validate() == true ? 'valid' : 'invalid'"
          >
            <div>{{ getFormula() }}</div>
          </div>
          <h4 class="f-weight-500 h4 mt-15 mb-05">Art Board</h4>

          <div class="artboard">
            <div
              v-for="(item, index) of field.selected_fields"
              v-bind:key="index"
            >
              <el-button
                v-if="showFieldButton(item.type)"
                size="medium"
                class="btn-variable"
                @click="swapFields(index)"
                :class="getActiveClass(index)"
              >
                <span v-if="item.type == 'OPERATOR'">{{ item.operator }}</span>

                <span v-if="item.type == 'FIELD'">{{ item.field_name }}</span>
                <span v-if="item.type == 'PARENTHESES'">{{
                  item.parentheses
                }}</span>
                <span v-if="item.type == 'FORMULA'">{{ item.formula }}</span>
                <span v-if="item.type == 'NUMBER'">{{ item.number }}</span>
                <span v-if="item.type == 'CURRENCY'">{{ item.currency }}</span>
                <span v-if="item.type == 'DATE'">{{ item.date }}</span>
                <span v-if="item.type == 'TIME'">{{ item.time }}</span>
                <span v-if="item.type == 'DATE_TIME_RANGE'">{{
                  item.date_time_range
                }}</span>
                <span v-if="item.type == 'DATE_RANGE'">{{
                  item.date_range
                }}</span>
                <span v-if="item.type == 'LIST'">{{ item.list }}</span>
                <span v-if="item.type == 'GLOBAL_VARIABLE'">{{
                  item.field_name
                }}</span>
                <span>
                  <img
                    src="@/assets/img/icons/transfer.svg"
                    alt="innerchange"
                  />
                </span>
              </el-button>
              <el-button
                v-if="item.type == 'OPERATOR'"
                :type="getOperationType(item.operator)"
                size="mini"
                class="btn-operation"
                @click="swapFields(index)"
                circle
                :class="getActiveClass(index)"
              >
                <img
                  v-if="item.operator == '+'"
                  src="@/assets/img/calculator/plus.svg"
                  alt="brackets-left"
                />
                <img
                  v-if="item.operator == '-'"
                  src="@/assets/img/calculator/minus.svg"
                  alt="brackets-left"
                />
                <img
                  v-if="item.operator == '*'"
                  src="@/assets/img/calculator/close.svg"
                  alt="brackets-left"
                />
                <img
                  v-if="item.operator == '/'"
                  src="@/assets/img/calculator/divider.svg"
                  alt="brackets-left"
                />
              </el-button>
              <el-button
                v-if="item.type == 'PARENTHESES' && item.parentheses == '('"
                type="text"
                effect="dark"
                class="text-button"
                >(</el-button
              >
              <el-button
                v-if="item.type == 'PARENTHESES' && item.parentheses == ')'"
                type="text"
                effect="dark"
                class="text-button"
                >)</el-button
              >
            </div>
          </div>
          <div class="variables-fields">
            <el-row :gutter="10">
              <el-col :md="12" :sm="12" :lg="8">
                <el-form-item label="Fields">
                  <el-select
                    v-model="showSelectField"
                    :disabled="checkDisabled('field')"
                    @change="onSelectField"
                    placeholder="Select Fields"
                  >
                    <el-option
                      v-for="item of getFields"
                      :key="item.key + '_' + item.template_name"
                      :value-key="'u_key'"
                      :label="getLabelName(item)"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- <el-col :md="12" :sm="12" :lg="8">
                  <el-form-item label="Global Variable">
                    <el-select
                      v-model="showSelectGlobalVariable"
                      @change="onSelectGlobalVariable($event)"
                      placeholder="Select Global variable"
                      :disabled="checkDisabled('global_variable')"
                    >
                      <el-option
                        v-for="item of getGlobalVariables()"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col> -->

              <el-col :md="12" :sm="12" :lg="80">
                <el-form-item label="Numbers">
                  <el-input
                    v-model="manualNumber"
                    placeholder="Enter Number"
                    :disabled="checkDisabled('number')"
                    style="margin-top: 4px"
                  >
                    <el-button
                      slot="append"
                      type="primary"
                      icon="el-icon-plus"
                      @click="addNumber"
                      :disabled="manualNumber < 0"
                    ></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="operaters">
            <el-button-group class="arthimetic-opeations">
              <el-button
                type="success"
                @click="onSelectOperator('+')"
                class="btn-operation"
                size="mini"
                :disabled="checkDisabled('operator')"
              >
                <img
                  src="@/assets/img/calculator/plus.svg"
                  alt="brackets-left"
                />
              </el-button>
              <el-button
                type="primary"
                @click="onSelectOperator('-')"
                class="btn-operation"
                size="mini"
                :disabled="checkDisabled('operator')"
              >
                <img
                  src="@/assets/img/calculator/minus.svg"
                  alt="brackets-left"
                />
              </el-button>
              <el-button
                type="info"
                @click="onSelectOperator('*')"
                class="btn-operation"
                size="mini"
                :disabled="checkDisabled('operator')"
              >
                <img
                  src="@/assets/img/calculator/close.svg"
                  alt="brackets-left"
                />
              </el-button>
              <el-button
                type="warning"
                @click="onSelectOperator('/')"
                class="btn-operation"
                size="mini"
                :disabled="checkDisabled('operator')"
              >
                <img
                  src="@/assets/img/calculator/divider.svg"
                  alt="brackets-left"
                />
              </el-button>
            </el-button-group>
            <el-button-group class="brackets-btn-group">
              <el-button
                type="info"
                @click="onSelectParentheses('(')"
                class="btn-operation"
                size="mini"
                :disabled="checkDisabled('parentheses', '(')"
                >(</el-button
              >
              <el-button
                type="info"
                @click="onSelectParentheses(')')"
                class="btn-operation"
                size="mini"
                :disabled="checkDisabled('parentheses', ')')"
                >)</el-button
              >

              <el-button
                type="danger"
                @click="popField()"
                class="btn-operation"
                size="mini"
                :disabled="field.selected_fields.length < 1"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </el-button-group>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import * as mexp from "math-expression-evaluator";

export default {
  components: {},
  data() {
    return {
      validations: [],
      options: ["+", "-", "*", "/"],
      parentheses: ["(", ")"],
      disableDropdown: this.field.lastSelected
        ? this.field.lastSelected
        : "operator",
      showSelectField: "",
      showSelectOperator: "",
      showSelectParentheses: "",
      showSelectGlobalVariable: "",
      manualNumber: "",
      activeClassName: "active",
      activeClassIndex: -1,
    };
  },
  props: [
    "field",
    "templatesData",
    "selfTemplate",
    "selfTemplateId",
    "isFromDocument",
  ],
  mounted() {
    this.field["filled_by"] = "SENDER";
    if (!this.field.result_type) {
      this.$set(this.field, "result_type", "");
    }
    if (!this.field.date_result_type) {
      this.$set(this.field, "date_result_type", "");
    }
    if (!this.field.time_result_type) {
      this.$set(this.field, "time_result_type", "");
    }
  },
  computed: {
    getFormulaAllowedFieldsForDataTable() {
      return (allFields, dataTableKey) => {
        let elements = [];
        allFields.forEach((el) => {
          if (
            this.field.key != el.key &&
            (el.type == "NUMBER" ||
              el.type == "DATE" ||
              el.type == "CURRENCY" ||
              el.type == "DATE" ||
              el.type == "LIST" ||
              el.type == "TIME" ||
              el.type == "DATE_TIME_RANGE" ||
              el.type == "DATE_RANGE" ||
              el.type == "FORMULA")
          ) {
            let obj = {
              template_id: null,
              template_name: "self/" + dataTableKey,
              field_id: el._id,
              field_name: el.label,
              label: el.label,
              key: el.key,
              u_key: el.key,
              input_type: el.type,
            };
            if (el.type == "CURRENCY") {
              obj = {
                ...obj,
                ...{
                  currency_type:
                    el.validations && el.validations.currency
                      ? el.validations.currency
                      : "USD",
                },
              };
            }
            if (el.type == "DATE") {
              obj = { ...obj, ...{ date: el.label } };
            }
            if (el.type == "FORMULA") {
              obj = {
                ...obj,
                ...{
                  formula: el.formula,
                  result_type: el.result_type ? el.result_type : "NUMBER",
                },
              };
            }
            elements.push(obj);
          }
        });

        return elements;
      };
    },

    getSelfTemplateFields() {
      return (selfTemplate, key) => {
        let elements = [];
        for (let index = 0; index < selfTemplate.length; index++) {
          console.log(
            "Field label",
            selfTemplate[index].label,
            selfTemplate[index]
          );
          if (selfTemplate[index].input_type === "NUMBER") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (selfTemplate[index].input_type === "CURRENCY") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              currency_type: selfTemplate[index].validations.currency,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (selfTemplate[index].input_type === "DATE") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (selfTemplate[index].input_type === "DATE_TIME") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (selfTemplate[index].input_type === "TIME") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (selfTemplate[index].input_type === "LIST") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (selfTemplate[index].input_type === "DATE_TIME_RANGE") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              date: selfTemplate[index].label,
              input_type: selfTemplate[index].input_type,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (
            selfTemplate[index].input_type === "ENTITY_VARIABLE" &&
            [
              "NUMBER",
              "CURRENCY",
              "DATE",
              "TIME",
              "LIST",
              "DATE_TIME_RANGE",
              "AGGREGATE_FUNCTION",
            ].indexOf(selfTemplate[index].inputType) != -1
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].inputType,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
          if (
            selfTemplate[index].input_type === "PAYMENT_VARIABLE" &&
            [
              "NUMBER",
              "CURRENCY",
              "DATE",
              "TIME",
              "DATE_TIME",
              "DATE_TIME_RANGE",
            ].indexOf(selfTemplate[index].inputType) != -1
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].inputType,
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          // if (
          //   selfTemplate[index].input_type === "SELECT" &&
          //   selfTemplate[index].is_list
          // ) {
          //   elements.push({
          //     template_name: "Self",
          //     field_name: selfTemplate[index].label,
          //     key: selfTemplate[index].key,
          //     u_key: "self" + selfTemplate[index].key,
          //     input_type: selfTemplate[index].input_type,
          //     ...(selfTemplate[index].data_table_key && {
          //       data_table_key: selfTemplate[index].data_table_key,
          //     }),
          //   });
          // }

          if (selfTemplate[index].input_type === "FORMULA") {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              formula: selfTemplate[index].formula,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }

          if (
            selfTemplate[index].input_type === "AGGREGATE_FUNCTION" &&
            key != selfTemplate[index].key
          ) {
            elements.push({
              template_name: "Self",
              field_name: selfTemplate[index].label,
              key: selfTemplate[index].key,
              u_key: "self" + selfTemplate[index].key,
              input_type: selfTemplate[index].input_type,
              result_type:
                selfTemplate[index] && selfTemplate[index].result_type
                  ? selfTemplate[index].result_type
                  : "NUMBER",
              ...(selfTemplate[index].data_table_key && {
                data_table_key: selfTemplate[index].data_table_key,
              }),
            });
          }
        }
        return elements;
      };
    },

    getFields() {
      let elements = [];
      for (let i = 0; i < this.templatesData.length; i++) {
        let fields =
          this.templatesData[i]._id != this.selfTemplateId
            ? this.templatesData[i].sections[0].fields
            : [];
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].input_type === "NUMBER") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].input_type,
            });
          }

          if (fields[j].input_type === "CURRENCY") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].input_type,
              currency_type: fields[j].validations.currency,
            });
          }

          if (fields[j].input_type === "DATE") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              date: fields[j].label,
              input_type: fields[j].input_type,
            });
          }
          if (fields[j].input_type === "DATE_TIME") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              date: fields[j].label,
              input_type: fields[j].input_type,
            });
          }
          if (fields[j].input_type === "LIST" && fields[j].is_list) {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].input_type,
            });
          }
          if (fields[j].input_type === "FORMULA") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              formula: fields[j].formula,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].input_type,
              result_type: fields[j].result_type,
            });
          }
          if (fields[j].input_type === "ENTITY_VARIABLE") {
            elements.push({
              template_id: this.templatesData[i]._id,
              template_name: this.templatesData[i].name,
              field_id: fields[j]._id,
              field_name: fields[j].label,
              key: fields[j].key,
              formula: fields[j].formula,
              u_key: this.templatesData[i].name.toLowerCase() + fields[j].key,
              input_type: fields[j].inputType,
            });
          }
        }
      }

      let selfTemplateFields = this.getSelfTemplateFields(
        this.selfTemplate,
        this.field.key
      );

      if (this.field.data_table_field_index > -1) {
        let dataTableFieldsElements = [];
        this.selfTemplate.forEach((e) => {
          if (e.input_type == "DATA_TABLE") {
            dataTableFieldsElements = [
              ...dataTableFieldsElements,
              ...e.data_table_columns.map((fl) => {
                fl.data_table_key = e.key;
                return fl;
              }),
            ];
          }
        });
        let selfTemplateDataTableFields = this.getSelfTemplateFields(
          dataTableFieldsElements,
          this.field.key
        );

        elements = [...selfTemplateDataTableFields, ...elements];
      }

      elements = [...selfTemplateFields, ...elements];
      return elements;
    },
  },
  methods: {
    getFormula() {
      let fields = this.field.selected_fields ? this.field.selected_fields : [];
      let formula = "";
      for (let index = 0; index < fields.length; index++) {
        if (fields[index]) {
          if (fields[index].type == "FIELD") {
            formula += " " + fields[index].field_name;
          }

          if (fields[index].type == "OPERATOR") {
            formula += " " + fields[index].operator;
          }

          if (fields[index].type == "PARENTHESES") {
            formula += " " + fields[index].parentheses;
          }

          if (fields[index].type == "FORMULA") {
            formula += " " + fields[index].formula;
          }

          if (fields[index].type == "NUMBER") {
            formula += " " + fields[index].number;
          }

          if (fields[index].type == "GLOBAL_VARIABLE") {
            formula += " " + fields[index].field_name;
          }

          if (fields[index].type == "DATE") {
            formula += " " + fields[index].date;
          }
        }
      }
      return formula;
    },
    getLabelName(item) {
      if (item.template_name) {
        return item.template_name + "/" + item.field_name;
      } else {
        return item.field_name;
      }
    },
    onSelectField(item) {
      if (item.formula) {
        item["type"] = "FORMULA";
      } else {
        item["type"] = "FIELD";
      }
      if (this.activeClassIndex > -1) {
        let fieldData = this.field.selected_fields[this.activeClassIndex];
        this.field.selected_fields.push(item);
        this.showSelectField = "";
        if (
          fieldData &&
          (fieldData.type == "FIELD" ||
            fieldData.type == "FORMULA" ||
            fieldData.type == "NUMBER" ||
            fieldData.type == "DATE" ||
            fieldData.type == "TIME" ||
            fieldData.type == "DATE_TIME_RANGE" ||
            fieldData.type == "DATE_RANGE" ||
            fieldData.type == "LIST" ||
            fieldData.type == "GLOBAL_VARIABLE")
        ) {
          this.field.selected_fields[this.activeClassIndex] = item;
          this.showSelectField = "";
          this.activeClassIndex = -1;
        }
      } else {
        this.field.selected_fields.push(item);
        this.showSelectField = "";
      }
      this.field.formula = this.getFormula();
    },

    onSelectOperator(operator) {
      if (
        this.activeClassIndex > -1 &&
        this.field.selected_fields[this.activeClassIndex].type == "OPERATOR"
      ) {
        this.field.selected_fields[this.activeClassIndex] = {
          type: "OPERATOR",
          operator,
        };
        this.showSelectOperator = "";
        this.activeClassIndex = -1;
      } else {
        this.field.selected_fields.push({ type: "OPERATOR", operator });
        this.showSelectOperator = "";
      }
      this.field.formula = this.getFormula();
    },

    onSelectParentheses(parentheses) {
      this.field.selected_fields.push({ type: "PARENTHESES", parentheses });
      this.showSelectParentheses = "";
      this.field.formula = this.getFormula();
    },

    addNumber() {
      if (this.activeClassIndex > -1) {
        let fieldData = this.field.selected_fields[this.activeClassIndex];
        if (
          fieldData.type == "FIELD" ||
          fieldData.type == "FORMULA" ||
          fieldData.type == "NUMBER" ||
          fieldData.type == "DATE" ||
          fieldData.type == "CURRENCY" ||
          fieldData.type == "TIME" ||
          fieldData.type == "LIST" ||
          fieldData.type == "GLOBAL_VARIABLE"
        ) {
          let number = this.manualNumber;
          this.field.selected_fields[this.activeClassIndex] = {
            type: fieldData.type == "DATE" ? "DATE" : "NUMBER",
            number,
          };
          this.manualNumber = "";
          this.activeClassIndex = -1;
        }
      } else {
        if (this.manualNumber > 0) {
          let number = this.manualNumber;
          this.field.selected_fields.push({
            type: "NUMBER",
            number,
          });
          this.manualNumber = "";
        }
      }
      this.field.formula = this.getFormula();
    },

    checkDisabled(type, item = "") {
      if (
        type == "field" ||
        type == "number" ||
        type == "currency" ||
        type == "global_variable" ||
        type == "date" ||
        type == "time" ||
        type == "list"
      ) {
        if (this.field.selected_fields.length) {
          if (this.activeClassIndex > -1) {
            let fieldData = this.field.selected_fields[this.activeClassIndex];
            if (
              fieldData &&
              (fieldData.type == "FIELD" ||
                fieldData.type == "NUMBER" ||
                fieldData.type == "DATE" ||
                fieldData.type == "TIME" ||
                fieldData.type == "LIST" ||
                fieldData.type == "CURRENCY" ||
                fieldData.type == "GLOBAL_VARIABLE" ||
                fieldData.type == "FORMULA")
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "FIELD" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "NUMBER" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "DATE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "TIME" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "DATE_TIME_RANGE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "DATE_RANGE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "LIST" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "GLOBAL_VARIABLE" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "FORMULA" ||
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "CURRENCY" ||
              (this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "PARENTHESES" &&
                this.field.selected_fields[
                  this.field.selected_fields.length - 1
                ].parentheses == ")")
            );
          }
        }
        return false;
      } else if (type == "operator") {
        if (this.field.selected_fields.length) {
          if (this.activeClassIndex > -1) {
            let fieldData = this.field.selected_fields[this.activeClassIndex];
            if (fieldData && fieldData.type == "OPERATOR") {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "OPERATOR" ||
              (this.field.selected_fields[this.field.selected_fields.length - 1]
                .type == "PARENTHESES" &&
                this.field.selected_fields[
                  this.field.selected_fields.length - 1
                ].parentheses == "(")
            );
          }
        }
        return true;
      } else if (type == "parentheses") {
        if (this.activeClassIndex > -1) {
          return true;
        } else {
          let openParenthesesCount = 0;
          let lastOpenIndex = -1;
          let closeParenthesesCount = 0;
          let selectedFieldsLength = this.field.selected_fields.length;
          let index = -1;
          this.field.selected_fields.map((el) => {
            index += 1;
            if (el.type == "PARENTHESES") {
              if (el.parentheses == "(") {
                openParenthesesCount += 1;
                lastOpenIndex = index;
              } else {
                closeParenthesesCount += 1;
              }
              return;
            }
          });
          let afterLastOpenLength = selectedFieldsLength - (lastOpenIndex + 1);
          if (!selectedFieldsLength) {
            return item == ")";
          } else if (selectedFieldsLength < 3) {
            //condition for formula
            return (
              this.field.selected_fields[selectedFieldsLength - 1].type !=
                "OPERATOR" || item == ")"
            );
          } else {
            if (item == "(") {
              if (
                this.field.selected_fields[selectedFieldsLength - 1].type ==
                "OPERATOR"
              ) {
                return false;
              } else if (
                this.field.selected_fields[selectedFieldsLength - 1].type ==
                  "PARENTHESES" &&
                this.field.selected_fields[selectedFieldsLength - 1]
                  .parentheses == "("
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              if (
                this.field.selected_fields[selectedFieldsLength - 1].type ==
                "OPERATOR"
              ) {
                return true;
              } else if (openParenthesesCount <= closeParenthesesCount) {
                return true;
              } else if (afterLastOpenLength < 2) {
                return true;
              } else if (
                afterLastOpenLength > 2 &&
                afterLastOpenLength % 2 == 0
              ) {
                if (
                  this.field.selected_fields[selectedFieldsLength - 1].type ==
                    "PARENTHESES" &&
                  this.field.selected_fields[selectedFieldsLength - 1]
                    .parentheses == ")"
                ) {
                  return false;
                }
                return true;
              } else {
                return false;
              }
            }
          }
        }
      } else {
        return false;
      }
    },
    removeElementFromFormula(ele, index) {
      this.field.selected_fields.splice(index, 1);
    },
    showFieldButton(type) {
      if (
        type == "FIELD" ||
        type == "NUMBER" ||
        type == "TIME" ||
        type == "DATE_TIME_RANGE" ||
        type == "DATE_RANGE" ||
        type == "LIST" ||
        type == "DATE" ||
        type == "CURRENCY" ||
        type == "GLOBAL_VARIABLE" ||
        type == "FORMULA"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getActiveClass(index) {
      if (this.activeClassIndex == index) {
        return "active";
      }
    },
    swapFields(index) {
      if (this.activeClassIndex > -1) {
        // let field = this.field.selected_fields[this.activeClassIndex]
        // this.field.selected_fields[this.activeClassIndex] = this.field.selected_fields[index]
        // this.field.selected_fields[index] = field
        this.activeClassIndex = -1;
      } else {
        this.activeClassIndex = index;
      }
    },
    popField() {
      this.field.selected_fields.pop();
      this.field.formula = this.getFormula();
    },
    getOperationType(operator) {
      if (operator == "+") {
        return "success";
      } else if (operator == "-") {
        return "primary";
      } else if (operator == "*") {
        return "info";
      } else if (operator == "/") {
        return "warning";
      }
    },
    validateFormula() {
      let selectedFieldsLength = this.field.selected_fields.length;
      if (selectedFieldsLength && selectedFieldsLength > 2) {
        let openParenthesesCount = 0;
        let closeParenthesesCount = 0;
        let lastFieldType = "";
        this.field.selected_fields.map((el) => {
          lastFieldType = el.type;
          if (el.type == "PARENTHESES") {
            if (el.parentheses == "(") {
              openParenthesesCount += 1;
            } else {
              closeParenthesesCount += 1;
            }
            return;
          }
        });
        if (
          lastFieldType == "OPERATOR" ||
          (lastFieldType == "PARENTHESES" &&
            this.field.selected_fields[selectedFieldsLength - 1].parentheses ==
              "(")
        ) {
          return true;
        } else if (openParenthesesCount != closeParenthesesCount) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    validate() {
      let exp = "";
      let lastField = "";
      let err = false;
      let selectedFieldsLength = this.field.selected_fields.length;
      if (selectedFieldsLength && selectedFieldsLength > 2) {
        this.field.selected_fields.map((el) => {
          if (el.type == "OPERATOR") {
            exp += el.operator + " ";
            if (lastField != "operator") {
              lastField = "operator";
            } else {
              err = true;
            }
          } else if (el.type == "PARENTHESES") {
            exp += el.parentheses + " ";
            lastField = "";
          } else {
            exp += Math.floor(Math.random() * Math.floor(9)) + " ";
            if (lastField != "number") {
              lastField = "number";
            } else {
              err = true;
            }
          }
          return;
        });
        try {
          if (err) {
            return false;
          } else {
            mexp.eval(exp);
            return true;
          }
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formula-group {
  margin-top: 0.5em;
  padding: 15px 0px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
</style>
<style lang="scss">
.formula-dialog .form-right .artboard .btn-variable {
  margin-top: 5px !important;
}
.currency:before {
  content: "*";
  color: red;
  position: absolute;
  margin-left: 107px;
  margin-top: 4px;
}
.formulaDateRequired::before {
  content: "*";
  color: red;
  position: absolute;
  margin-left: 120px;
  margin-top: 4px;
}
.formula-box {
  &.invalid {
    border-color: #ff1b48 !important;
    background-color: #ffecf0 !important;
  }
  &.valid {
    border-color: #0eac28 !important;
    background-color: #d6ffd6 !important;
  }
}
.artboard {
  flex-wrap: wrap !important;
}
.formula-group {
  .el-tag {
    font-size: 1em;
  }
}
.show-formula {
  margin-bottom: 1em;
  margin-top: 1em;
  h4 {
    margin-top: 0;
  }
  & > div {
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
  }
  .status-label {
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 0 10px;
  }
}
.paragraphScroll {
  height: 450px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  width: 100%;
}
.paragraphScroll::-webkit-scrollbar {
  width: 0.3em;
}
.paragraphScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
i:hover {
  /* background-color: #dbf3fa; */
}
i:hover {
  color: rgb(114, 212, 230);

  transform: translate(-10%, -10%);
}
</style>
